var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tag",
    { staticClass: "tag-boost", attrs: { color: _vm.color } },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("getEventTypePrettyString")(_vm.product)) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }