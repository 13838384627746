var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code-redeem" },
    [
      _c("h2", [_vm._v("รายการสินค้า")]),
      _vm._l(_vm.txItemMetas, function(ref) {
        var txItem = ref.txItem
        var customerTxItemId = ref.customerTxItemId
        var amount = ref.amount
        return _c(
          "div",
          { key: customerTxItemId },
          [
            _c(
              "a-form-item",
              {
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "ประเภท"
                }
              },
              [
                _c("ProductTagMini", {
                  attrs: { product: txItem.mirrorProduct }
                })
              ],
              1
            ),
            _c(
              "a-form-item",
              {
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "กีฬา"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.mirrorProduct.field.sport.displayName || "-") +
                    "\n        "
                )
              ]
            ),
            _c(
              "a-form-item",
              {
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "รายละเอียด"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.mirrorProduct.desc || "-") +
                    "\n        "
                )
              ]
            ),
            _c(
              "a-form-item",
              {
                staticClass: "code-redeem__form-item--important",
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "วัน/เดือน/ปี (เวลา)"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("getStartEndPrettyString")(_vm.mirrorProduct)
                    ) +
                    "\n        "
                )
              ]
            ),
            _c(
              "a-form-item",
              {
                staticClass: "code-redeem__form-item--important",
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "ชื่อสนาม"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(txItem.mirrorProduct.field.name || "-") +
                    "\n        "
                )
              ]
            ),
            _c(
              "a-form-item",
              {
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "รายละเอียดสนาม"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(txItem.mirrorProduct.field.description || "-") +
                    "\n        "
                )
              ]
            ),
            _c(
              "a-form-item",
              {
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "ประเภท"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(txItem.mirrorVariant.name || "-") +
                    "\n        "
                )
              ]
            ),
            _c(
              "a-form-item",
              {
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "ราคาต่อชิ้น"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("formatCurrency")(txItem.price)) +
                    "\n            "
                ),
                _c("s", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatCurrency")(txItem.mirrorVariant.fullPrice)
                    )
                  )
                ])
              ]
            ),
            _c(
              "a-form-item",
              {
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "จำนวนที่ใช้ได้/ทั้งหมด"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(txItem.unusedAmount) +
                    " / " +
                    _vm._s(txItem.amount) +
                    "\n        "
                )
              ]
            ),
            _c(
              "a-form-item",
              {
                staticClass: "code-redeem__form-item--most-important",
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "จำนวนที่จะใช้งาน"
                }
              },
              [_vm._v("\n             " + _vm._s(amount) + "\n        ")]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }