var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "ใช้งานรหัส (QR/Code)" } },
    [
      _c("StadiumBoostUseForm", {
        attrs: { "init-code": _vm.code.toUpperCase() }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }