<template>
    <div class="code-payment">
        <h2>รายการสินค้า</h2>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภท">
            <ProductTagMini :product="mirrorProduct" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="กีฬา">
            {{ mirrorProduct.field.sport.displayName || "-" }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="รายละเอียด">
            {{ mirrorProduct.desc || "-" }}
        </a-form-item>
        <a-form-item
            class="code-payment__form-item--most-important"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="วันเริ่ม-จบ">
            {{ mirrorProduct | getStartEndPrettyString }}
        </a-form-item>
        <a-form-item
            class="code-payment__form-item--most-important"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อสนาม">
            {{ mirrorProduct.field.name || "-" }}
        </a-form-item>

        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="รายละเอียดสนาม">
            {{ mirrorProduct.field.description || "-" }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภท">
            {{ mirrorVariant.name || "-" }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ราคา/อัน">
            {{ mirrorVariant.payOfflinePrice | formatCurrency }}
            <s>{{ mirrorVariant.fullPrice | formatCurrency }}</s>
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="จำนวน">
            {{ txItem.amount }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ราคารวม">
            {{ mirrorVariant.payOfflinePrice * txItem.amount | formatCurrency }}
        </a-form-item>
    </div>
</template>
<script>
import ProductTagMini from '@/components/ProductTagMini.vue'

export default {
    components: {
        ProductTagMini,
    },
    props: {
        code: {
            type: Object,
            required: true,
        },
    },
    computed: {
        tx () {
            return this.code.tx
        },
        txItem () {
            return this.tx.txItems[0]
        },
        mirrorProduct () {
            return this.txItem.mirrorProduct
        },
        mirrorVariant () {
            return this.txItem.mirrorVariant
        },
    },
}
</script>
<style lang="stylus">
.code-payment__form-item--important .ant-form-item-control-wrapper
    font-weight bold
    font-size 16px
.code-payment__form-item--most-important
    font-weight bold
    font-size 16px
</style>
