<template>
    <a-tag :color="color" class="tag-boost">
        {{ product | getEventTypePrettyString }}
    </a-tag>
</template>
<script>
import { EventType } from '@/utils/enum'

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        color () {
            switch (this.product.eventType) {
            case EventType.RESERVATION:
                return '#52c41a'
            case EventType.TRAINING:
                return '#1890ff'
            case EventType.TOURNAMENT:
                return '#fa541c'
            default:
                return '#f9a825'
            }
        },
    },
}
</script>
