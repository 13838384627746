<template>
    <div>
        <VFormItemRedeemCode v-model="redeemCode" :is-error="isError" />
        <a-form-item
            :wrapper-col="{ span: 12,offset: 4 }">
            <a-button
                type="primary"
                @click="searchBoostByRedeemCode">
                ค้นหา
            </a-button>
        </a-form-item>
        <div v-if="code !== null">
            <a-row>
                <a-col :span="20">
                    <Code :code="code" />
                </a-col>
                <a-col :span="4">
                    <a-form-item
                        :wrapper-col="{ xxl: { span: 12,offset: 4 },
                                        lg: { span: 18,offset: 4 } }">
                        <a-button
                            v-if="code.type === 'PAYMENT' "
                            type="primary"
                            @click="usePaymentCode">
                            บันทึกการจ่ายเงิน
                        </a-button>
                        <a-button
                            v-else
                            type="primary"
                            @click="useRedeemCode">
                            บันทึกการใช้สินค้า
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
import VFormItemRedeemCode from '@/components/VFormItemRedeemCode.vue'
import Code from '@/components/Code.vue'
import { rootActions } from '@/store/types'

export default {
    components: {
        VFormItemRedeemCode,
        Code,
    },
    props: {
        initCode: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            redeemCode: this.initCode,
            redeemStatus: null,
            isError: false,
        }
    },
    computed: {
        code () {
            return this.$store.state.code.data
        },
    },
    methods: {
        async searchBoostByRedeemCode () {
            if (this.redeemCode !== null && this.redeemCode !== '' && this.redeemCode.length === 16) {
                this.isError = false
                const res = await this.$store.dispatch(
                    rootActions.LOAD_CODE, this.redeemCode,
                )
                return res
            }
            this.isError = true
            return false
        },
        async useRedeemCode () {
            const res = await this.$store.dispatch(
                rootActions.USE_REDEEM_CODE,
                {
                    txItemIds: this.code.txItemMetas.map(
                        (meta) => meta.customerTxItemId,
                    ),
                    redeemCode: this.code.code,
                },
            )
            if (res.isSuccess()) {
                this.$success({
                    title: 'ใช้โค้ดสำเร็จ',
                    onOk: this.clearCode,
                })
            }
            return res
        },
        async usePaymentCode () {
            const res = await this.$store.dispatch(
                rootActions.USE_PAYMENT_CODE, { txId: this.code.tx.id, redeemCode: this.code.code },
            )
            if (res.isSuccess()) {
                this.$success({
                    title: 'บันทึกการจ่ายเงินสำเร็จ',
                    onOk: this.clearCode,
                })
            }
            return res
        },
        clearCode () {
            this.redeemCode = null
            this.$store.dispatch(
                rootActions.CLEAR_ACTIVE_CODE,
            )
        },
    },
}
</script>
<style lang="stylus">
.stadium-boost-update-form_action-button
    float: right
    clear: both
</style>
