<template>
    <div class="code">
        <h1>ข้อมูล ธุรกรรม</h1>
        <a-form-item
            class="code__redeem-code"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="โค้ด">
            {{ code.code | formatRedeemCode }}
        </a-form-item>
        <a-form-item
            class="code__redeem-code"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภทโค้ด">
            <a-tag :color="typeTagColor" class="code__type-tag">
                {{ code.type | formatCodeType }}
            </a-tag>
        </a-form-item>
        <a-form-item
            v-if="code.type == 'PAYMENT'"
            class="code__redeem-code"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ราคารวม">
            {{ tx.price | formatCurrency }}
        </a-form-item>
        <h2>ลูกค้า</h2>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อผู้ใช้">
            {{ customer.username }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            class="code__form-item--important"
            label="ชื่อจริง">
            {{ customer.firstName || "-" }}
        </a-form-item>
        <a-form-item
            class="code__form-item--important"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="นามสกุล">
            {{ customer.lastName || "-" }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อเล่น">
            {{ customer.nickName || "-" }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="เบอร์โทร">
            {{ customer.phoneNumber || "-" }}
        </a-form-item>
        <CodePayment v-if="code.type == 'PAYMENT'" :code="code" />
        <CodeRedeem v-else-if="code.type == 'REDEEM'" :code="code" />
    </div>
</template>
<script>
import CodePayment from '@/components/CodePayment.vue'
import CodeRedeem from '@/components/CodeRedeem.vue'
import { CodeType } from '@/utils/enum'

export default {
    components: {
        CodePayment,
        CodeRedeem,
    },
    props: {
        code: {
            type: Object,
            required: true,
        },
    },
    computed: {
        tx () {
            return this.code.tx
        },
        customer () {
            return this.code.generatedByUser
        },
        typeTagColor () {
            return this.code.type === CodeType.PAYMENT ? 'red' : 'green'
        },
    },
}
</script>
<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

.code
    .ant-form-item
        margin-bottom 0
        label
            color $primary-color
.code__redeem-code .ant-form-item-control-wrapper
    font-family: 'Inconsolata', monospace;
    font-size: 16px
    font-weight: bold
.code__form-item--important  .ant-form-item-control-wrapper
    font-weight bold
    font-size 16px
.code__type-tag
    font-size: 16px;
    padding: 4px 16px 4px 16px;
    font-weight: 500;
</style>
