<template>
    <div class="code-redeem">
        <h2>รายการสินค้า</h2>
        <div v-for="{ txItem,customerTxItemId,amount } in txItemMetas" :key="customerTxItemId">
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="ประเภท">
                <ProductTagMini :product="txItem.mirrorProduct" />
            </a-form-item>
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="กีฬา">
                {{ mirrorProduct.field.sport.displayName || "-" }}
            </a-form-item>
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="รายละเอียด">
                {{ mirrorProduct.desc || "-" }}
            </a-form-item>
            <a-form-item
                class="code-redeem__form-item--important"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="วัน/เดือน/ปี (เวลา)">
                {{ mirrorProduct | getStartEndPrettyString }}
            </a-form-item>
            <a-form-item
                class="code-redeem__form-item--important"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="ชื่อสนาม">
                {{ txItem.mirrorProduct.field.name || "-" }}
            </a-form-item>
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="รายละเอียดสนาม">
                {{ txItem.mirrorProduct.field.description || "-" }}
            </a-form-item>
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="ประเภท">
                {{ txItem.mirrorVariant.name || "-" }}
            </a-form-item>
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="ราคาต่อชิ้น">
                {{ txItem.price | formatCurrency }}
                <s>{{ txItem.mirrorVariant.fullPrice | formatCurrency }}</s>
            </a-form-item>
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="จำนวนที่ใช้ได้/ทั้งหมด">
                {{ txItem.unusedAmount }} / {{ txItem.amount }}
            </a-form-item>
            <a-form-item
                class="code-redeem__form-item--most-important"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="จำนวนที่จะใช้งาน">
                &nbsp;{{ amount }}
            </a-form-item>
        </div>
    </div>
</template>
<script>
import ProductTagMini from '@/components/ProductTagMini.vue'

export default {
    components: {
        ProductTagMini,
    },
    props: {
        code: {
            type: Object,
            required: true,
        },
    },
    computed: {
        txItemMetas () {
            return this.code.txItemMetas
        },
        mirrorProduct () {
            return this.txItemMetas[0].txItem.mirrorProduct
        },
    },
}
</script>
<style lang="stylus">
.code-redeem__form-item--important .ant-form-item-control-wrapper
    font-weight bold
    font-size 16px
.code-redeem__form-item--most-important
    font-weight bold
    font-size 16px
</style>
