var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code" },
    [
      _c("h1", [_vm._v("ข้อมูล ธุรกรรม")]),
      _c(
        "a-form-item",
        {
          staticClass: "code__redeem-code",
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "โค้ด"
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("formatRedeemCode")(_vm.code.code)) +
              "\n    "
          )
        ]
      ),
      _c(
        "a-form-item",
        {
          staticClass: "code__redeem-code",
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ประเภทโค้ด"
          }
        },
        [
          _c(
            "a-tag",
            {
              staticClass: "code__type-tag",
              attrs: { color: _vm.typeTagColor }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("formatCodeType")(_vm.code.type)) +
                  "\n        "
              )
            ]
          )
        ],
        1
      ),
      _vm.code.type == "PAYMENT"
        ? _c(
            "a-form-item",
            {
              staticClass: "code__redeem-code",
              attrs: {
                "label-col": { span: 4 },
                "wrapper-col": { span: 12 },
                label: "ราคารวม"
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("formatCurrency")(_vm.tx.price)) +
                  "\n    "
              )
            ]
          )
        : _vm._e(),
      _c("h2", [_vm._v("ลูกค้า")]),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อผู้ใช้"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.customer.username) + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          staticClass: "code__form-item--important",
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อจริง"
          }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.customer.firstName || "-") + "\n    "
          )
        ]
      ),
      _c(
        "a-form-item",
        {
          staticClass: "code__form-item--important",
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "นามสกุล"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.customer.lastName || "-") + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อเล่น"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.customer.nickName || "-") + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "เบอร์โทร"
          }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.customer.phoneNumber || "-") + "\n    "
          )
        ]
      ),
      _vm.code.type == "PAYMENT"
        ? _c("CodePayment", { attrs: { code: _vm.code } })
        : _vm.code.type == "REDEEM"
        ? _c("CodeRedeem", { attrs: { code: _vm.code } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }