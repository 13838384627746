<template>
    <VPage title="ใช้งานรหัส (QR/Code)">
        <StadiumBoostUseForm :init-code="code.toUpperCase()" />
    </VPage>
</template>
<script>
import StadiumBoostUseForm from '@/components/StadiumBoostUseForm.vue'
import VPage from '@/components/VPage.vue'

export default {
    components: {
        StadiumBoostUseForm,
        VPage,
    },
    props: {
        code: {
            type: String,
            default: '',
        },
    },
}
</script>
