var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VFormItemRedeemCode", {
        attrs: { "is-error": _vm.isError },
        model: {
          value: _vm.redeemCode,
          callback: function($$v) {
            _vm.redeemCode = $$v
          },
          expression: "redeemCode"
        }
      }),
      _c(
        "a-form-item",
        { attrs: { "wrapper-col": { span: 12, offset: 4 } } },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.searchBoostByRedeemCode }
            },
            [_vm._v("\n            ค้นหา\n        ")]
          )
        ],
        1
      ),
      _vm.code !== null
        ? _c(
            "div",
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 20 } },
                    [_c("Code", { attrs: { code: _vm.code } })],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            "wrapper-col": {
                              xxl: { span: 12, offset: 4 },
                              lg: { span: 18, offset: 4 }
                            }
                          }
                        },
                        [
                          _vm.code.type === "PAYMENT"
                            ? _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.usePaymentCode }
                                },
                                [
                                  _vm._v(
                                    "\n                        บันทึกการจ่ายเงิน\n                    "
                                  )
                                ]
                              )
                            : _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.useRedeemCode }
                                },
                                [
                                  _vm._v(
                                    "\n                        บันทึกการใช้สินค้า\n                    "
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }