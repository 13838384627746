var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code-payment" },
    [
      _c("h2", [_vm._v("รายการสินค้า")]),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ประเภท"
          }
        },
        [_c("ProductTagMini", { attrs: { product: _vm.mirrorProduct } })],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "กีฬา"
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.mirrorProduct.field.sport.displayName || "-") +
              "\n    "
          )
        ]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "รายละเอียด"
          }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.mirrorProduct.desc || "-") + "\n    "
          )
        ]
      ),
      _c(
        "a-form-item",
        {
          staticClass: "code-payment__form-item--most-important",
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "วันเริ่ม-จบ"
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("getStartEndPrettyString")(_vm.mirrorProduct)) +
              "\n    "
          )
        ]
      ),
      _c(
        "a-form-item",
        {
          staticClass: "code-payment__form-item--most-important",
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อสนาม"
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.mirrorProduct.field.name || "-") +
              "\n    "
          )
        ]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "รายละเอียดสนาม"
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.mirrorProduct.field.description || "-") +
              "\n    "
          )
        ]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ประเภท"
          }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.mirrorVariant.name || "-") + "\n    "
          )
        ]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ราคา/อัน"
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("formatCurrency")(_vm.mirrorVariant.payOfflinePrice)
              ) +
              "\n        "
          ),
          _c("s", [
            _vm._v(
              _vm._s(_vm._f("formatCurrency")(_vm.mirrorVariant.fullPrice))
            )
          ])
        ]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "จำนวน"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.txItem.amount) + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ราคารวม"
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("formatCurrency")(
                  _vm.mirrorVariant.payOfflinePrice * _vm.txItem.amount
                )
              ) +
              "\n    "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }