var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-item",
    {
      attrs: {
        label: "รหัส (QR/Code)",
        "label-col": { span: 4 },
        "wrapper-col": { span: 16 },
        "validate-status": _vm.isError ? "error" : null,
        help: _vm.helpMsg
      }
    },
    [
      _vm._l(_vm.codeArray, function(item, index) {
        return [
          index % 4 === 0 && index !== 0
            ? [
                _c(
                  "div",
                  {
                    key: index + "-separator",
                    staticClass: "v-form-item-redeem-code__input-box"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "v-form-item-redeem-code__separator" },
                      [_vm._v("\n                    -\n                ")]
                    )
                  ]
                )
              ]
            : _vm._e(),
          _c(
            "div",
            {
              key: index + "-input-box",
              staticClass: "v-form-item-redeem-code__input-box"
            },
            [
              _c("a-input", {
                ref: "redeemCodeInput",
                refInFor: true,
                staticClass: "v-form-item-redeem-code__input",
                attrs: { type: "text", value: _vm.codeArray[index] },
                on: {
                  keydown: function($event) {
                    $event.preventDefault()
                    return _vm.handlePress($event, index)
                  }
                }
              })
            ],
            1
          )
        ]
      }),
      _c("br"),
      _c(
        "a-button",
        {
          staticClass: "v-form-item-redeem-code__button-action",
          on: { click: _vm.copyCode }
        },
        [
          _c("a-icon", { attrs: { type: "copy" } }),
          _vm._v("\n        คัดลอก\n    ")
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticClass: "v-form-item-redeem-code__button-action",
          on: { click: _vm.pasteFromClipboard }
        },
        [
          _c("a-icon", { attrs: { type: "form" } }),
          _vm._v("\n        วาง\n    ")
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }