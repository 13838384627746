<template>
    <a-form-item
        label="รหัส (QR/Code)"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="isError ? 'error' : null"
        :help="helpMsg">
        <template
            v-for="(item, index) in codeArray">
            <template v-if="index % 4 === 0 && index !== 0">
                <div :key="index + '-separator'" class="v-form-item-redeem-code__input-box">
                    <div class="v-form-item-redeem-code__separator">
                        -
                    </div>
                </div>
            </template>
            <div :key="index + '-input-box'" class="v-form-item-redeem-code__input-box">
                <a-input
                    ref="redeemCodeInput"
                    type="text"
                    class="v-form-item-redeem-code__input"
                    :value="codeArray[index]"
                    @keydown.prevent="handlePress($event, index)" />
            </div>
        </template>
        <br>
        <a-button class="v-form-item-redeem-code__button-action" @click="copyCode">
            <a-icon type="copy" />
            คัดลอก
        </a-button>
        <a-button
            class="v-form-item-redeem-code__button-action"
            @click="pasteFromClipboard">
            <a-icon type="form" />
            วาง
        </a-button>
    </a-form-item>
</template>
<script>
import { copyTextToClipboard } from '@/utils/utils'

export default {
    props: {
        isError: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        codeLength: {
            type: Number,
            default: 16,
        },
    },
    data () {
        return {
            markEmptyChar: ' ',
        }
    },
    computed: {
        helpMsg () {
            return !this.isError ? 'สามารถใส่รหัสได้ทุกประเภทในระบบ ไม่ว่าจะเป็น รหัสการจ่ายเงิน, รหัสการใช้งานโปรโมชั่น และ อื่นๆ' : 'กรอกรหัสให้ครบด้วยค่ะ'
        },
        codeArray () {
            return this.splitCodeToCodeArray(this.value)
        },
        code () {
            return this.combineCharToString(this.codeArray)
        },
    },
    methods: {
        async pasteFromClipboard () {
            const text = await navigator.clipboard.readText()
            if (!text) return
            const updatedCodeArray = text.toUpperCase().split('').slice(0, 16)
            const updatedCode = this.combineCharToString(updatedCodeArray)
            this.$emit('input', updatedCode)
            if (
                this.isComplete(updatedCodeArray)
            ) {
                this.$emit('inputComplete', updatedCode)
            }
        },
        splitCodeToCodeArray (code) {
            if (!code) return Array.from({ length: 16 }, () => this.markEmptyChar)
            const codeArray = code.toUpperCase().split('').slice(0, 16)
            const countEmptyValue = this.codeLength - codeArray.length
            for (let i = 0; i < countEmptyValue; i += 1) {
                codeArray.push(this.markEmptyChar)
            }
            return codeArray
        },
        copyCode () {
            copyTextToClipboard(this.code)
        },
        handlePress (e, index) {
            const content = this.$refs.redeemCodeInput[index].value
            if (/^\w{1}$/.test(e.key)) {
                this.updateCodeArray(index, e.key.toUpperCase())
                this.switchFocusAndSelect(index, 1)
            } else if (['ArrowRight', 'Tab'].indexOf(e.key) > -1) {
                this.switchFocusAndSelect(index, 1)
            } else if (['ArrowLeft'].indexOf(e.key) > -1) {
                this.switchFocusAndSelect(index, -1)
            } else if (['Backspace'].indexOf(e.key) > -1) {
                if (content && content !== this.markEmptyChar) {
                    this.updateCodeArray(index, this.markEmptyChar)
                } else {
                    this.switchFocusAndClear(index, -1)
                }
            }
        },
        combineCharToString (chars) {
            const formatedChars = chars.map((char) => (char === '' ? this.markEmptyChar : char))
            return formatedChars.reduce((prev, current) => prev + current)
        },
        updateCodeArray (index, value) {
            const codeArray = this.splitCodeToCodeArray(this.value)
            codeArray.splice(index, 1, value)
            const updatedCodeArray = codeArray
            const updatedCode = this.combineCharToString(updatedCodeArray)
            this.$emit('input', updatedCode, index)
            if (
                this.isComplete(updatedCodeArray)
            ) {
                this.$emit('inputComplete', updatedCode, index)
            }
        },
        isComplete (codeArray) {
            return codeArray
            && !codeArray.find((char) => char === this.markEmptyChar)
            && codeArray.length === this.codeLength
        },
        switchFocusAndSelect (index, direction = 1) {
            if (this.$refs.redeemCodeInput[index + direction]) {
                this.$refs.redeemCodeInput[index + direction].select()
            }
        },
        switchFocusAndClear (index, direction = 1) {
            if (this.$refs.redeemCodeInput[index + direction]) {
                this.updateCodeArray(index + direction, this.markEmptyChar)
                this.$refs.redeemCodeInput[index + direction].select()
            }
        },
    },
}
</script>
<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

$box-width=36px

.v-form-item-redeem-code {
  display: flex;
  justify-content: center;
}
.v-form-item-redeem-code__input-box {
  width: $box-width;
  height: @width;
  display: inline-block;
}
.v-form-item-redeem-code__input {
  width: $box-width;
  height: @width;
  line-height: @width;
  font-size: 24px;
  padding: 0px;
  text-align: center;
  color: #494949;
  font-weight: 400;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  box-sizing: border-box;
  -webkit-appearance: none;
}
.v-form-item-redeem-code__separator {
  width: $box-width;
  height: @width;
  line-height: @width;
  font-size: 24px;
  padding: 0px;
  text-align: center;
  color: #494949;
  font-weight: 400;
  background: #fff;
  box-sizing: border-box;
  -webkit-appearance: none;
}
.v-form-item-redeem-code__input:focus {
  outline: none;
  border: 1px solid #97cee5;
  box-shadow: 0 0 0 3px rgba(131, 192, 253, 0.5);
  z-index: 1000
}
.v-form-item-redeem-code__input
    font-family: 'Inconsolata', monospace;

.v-form-item-redeem-code__button-action
    margin-right 8px
</style>
